import React from 'react';
import GatsbyImage from 'gatsby-image';
import clsx from 'clsx';
import { Link } from 'gatsby';

import ExternalLinkIcon from './shared/ExternalLinkIcon';
import { AvailableColors, Feature as IFeature } from '../types';

interface Props extends IFeature {
  reverse: boolean;
}

function getBackdropColor(color: AvailableColors) {
  if (color === 'blue') {
    return 'bg-blue-50';
  }

  if (color === 'yellow') {
    return 'bg-yellow-50';
  }

  if (color === 'gray') {
    return 'bg-gray-50';
  }
}

function getLeftBorderColor(color: AvailableColors) {
  if (color === 'blue') {
    return 'border-blue-500';
  }

  if (color === 'yellow') {
    return 'border-yellow-500';
  }

  if (color === 'gray') {
    return 'border-gray-500';
  }
}

const Feature: React.FC<Props> = ({
  color,
  description,
  image,
  items,
  linkWithDescription,
  reverse = false,
  title,
}) => {
  return (
    <section className="flex flex-col xl:container">
      <div
        className={clsx([
          'flex flex-col lg:flex-row lg:items-center',
          reverse && 'lg:flex-row-reverse',
        ])}
      >
        <div
          className={clsx([
            'container transform lg:my-10 lg:w-5/12 lg:px-0',
            reverse
              ? 'lg:-translate-x-8 xl:-translate-x-24'
              : 'lg:translate-x-8 xl:translate-x-24',
          ])}
        >
          <div className="aspect-w-4 aspect-h-3">
            <GatsbyImage
              alt={image.alt}
              className="!absolute rounded-2xl object-cover"
              fluid={image.asset.fluid}
            />
          </div>
        </div>

        <div
          className={clsx([
            getBackdropColor(color),
            '-mt-16 pt-28 pb-16 lg:mt-0 lg:w-7/12 lg:py-12',
          ])}
        >
          <div
            className={clsx([
              'container lg:flex lg:flex-col lg:justify-center lg:px-20 lg:py-12 xl:py-8',
              reverse ? 'xl:pl-24 xl:pr-48' : 'xl:pl-40 xl:pr-24',
            ])}
          >
            <h3 className="text-3xl font-bold leading-snug tracking-tighter text-blue-700 md:text-4xl xl:text-5xl">
              {title}
            </h3>

            {description && (
              <p className="mt-4 whitespace-pre-wrap text-blue-800 2xl:text-lg">
                {description}
              </p>
            )}

            {items?.length > 0 && (
              <div className="mt-10">
                {items.map((item) => (
                  <p
                    className={clsx([
                      'mb-4 border-l-4 px-4 text-blue-700',
                      getLeftBorderColor(color),
                    ])}
                    key={item}
                  >
                    {item}
                  </p>
                ))}
              </div>
            )}

            {linkWithDescription?.url && (
              <Link
                className="group mt-10 block font-medium text-blue-700 hover:text-blue-600 hover:underline"
                to={linkWithDescription.url}
              >
                {linkWithDescription.description}
                <ExternalLinkIcon />
              </Link>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feature;
