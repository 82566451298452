import React from 'react';

import ExternalLinkIcon from './shared/ExternalLinkIcon';
import { Quotes } from '../assets/Icons';

interface Props {
  author: string;
  content: string;
  link: string;
}

const Quote: React.FC<Props> = ({ author, content, link }) => {
  return (
    <section className="container">
      <figure className="relative mx-auto md:w-8/12">
        <span className="md:absolute md:-left-20 md:-top-2">
          <Quotes className="h-12 w-12 text-gray-600 md:h-16 md:w-16" />
        </span>

        <blockquote
          cite={link}
          className="pb-4 font-serif text-2xl italic leading-normal text-gray-600"
        >
          <p>{content}</p>
        </blockquote>

        <figcaption className="group md:text-xl">
          —{' '}
          <a
            className="hover:text-blue-600 hover:underline"
            href={link}
            rel="noopener noreferrer"
            target="_blank"
          >
            {author}
          </a>
          <ExternalLinkIcon />
        </figcaption>
      </figure>
    </section>
  );
};

export default Quote;
